import { Image } from "./types";

export const designImages: Image[] = [
  { filename: "llanerch-diner.jpg", text: "Llanerch Diner" },
  { filename: "dimple-date.jpg", text: "DimpleDate.com" },
  { filename: "obrien-door-hanger.jpg", text: "O'Brien Family Tree Service Door Hanger" },
  { filename: "wicca-singles.jpg", text: "Wicca Singles" },
  { filename: "cross-painting.jpg", text: "Cross Painting and Powerwashing" },
  { filename: "michael-logan.jpg", text: "Michael A. Logan, Attorney at Law" },
  { filename: "charmed-dentistry.jpg", text: "Charmed Pediatric Dentistry" },
  { filename: "rcma.jpg", text: "RCMA Online" },
  { filename: "obrien-mobile.jpg", text: "O'Brien Family Tree Service Mobile Site Mock Up" },
  { filename: "gimmie-sugar.jpg", text: "Gimmie Some Sugar Bakery" },
  { filename: "blinds-4-u.jpg", text: "Blinds-4-U" },
  { filename: "amalia-margarets.jpg", text: "Amalia Margarets Inc." },
];

export const retouchImages = [
  { filename: "retouch-1.png", text: "Christopher &amp; Banks" },
  { filename: "retouch-2.png", text: "Retouch Work" },
  { filename: "retouch-3.png", text: "Retouch Work" },
  { filename: "retouch-4.png", text: "Retouch Work" },
  { filename: "retouch-5.png", text: "Retouch Work" },
  { filename: "retouch-6.png", text: "New York &amp; Company; Eva Mendes collection" },
  { filename: "retouch-7.png", text: "Retouch Work" },
  { filename: "retouch-8.png", text: "Retouch Work" },
  { filename: "retouch-9.png", text: "Retouch Work" },
  { filename: "retouch-10.png", text: "Retouch Work" },
  { filename: "retouch-11.png", text: "Retouch Work" },
  { filename: "retouch-12.png", text: "Retouch Work" },
];
